import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import KnightModal from "./KnightModal";
import axios from 'axios';
import serverUrl from "constants";
import { OverlayTrigger, Popover } from "react-bootstrap";

const AddKnightModal = ({ show, handleClose, knights, currentKnights }) => {
    const [formValue, setFormValue] = useState({});
    const [validated, setValidated] = useState(false);

    useEffect(() => {
        const resetForm = () => {
            setFormValue({
                ...formValue,
                name: '',
                level: '',
                saved_exp: '',
                equipment: '',
                strengthTalentLevels: '',
                intelTalentLevels: '',
                leadershipTalentLevels: '',
                charismaTalentLevels: '',
                strengthBonus: '',
                intelBonus: '',
                leadershipBonus: '',
                charismaBonus: '',
                decorTalent: '0,;0,',
                loverAttribute: '0,0',
                strengthBookAtt: '',
                intelBookAtt: '',
                leadershipBookAtt: '',
                charismaBookAtt: '',
                mountStr: '',
                mountIntel: '',
                mountLead: '',
                mountChar: '',
                tier: '',
                loverPts: '',
                skillLvlHp: '1',
                skillLvlAttack: '1'
            });
            setValidated(false);
        };
        resetForm();
    }, [show]);

    const handleAdd = async (e) => {
        e.preventDefault();

        //const formData = { username: formValue.username, server: formValue.server, uid: formValue.uid };
        await axios.post(serverUrl + "knights.php", formValue);
        handleClose();

    };

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        console.log("submit");
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
            return;
        }
        setValidated(true);
        handleAdd(event);
    };

    const popover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Enter knight information here</Popover.Header>
            <Popover.Body>
                <p>Select a knight you haven&apos;t added yet, then select its equipment and adjust the level as needed.</p>
                <p>Watch out for fields that require a <b>level</b> instead of the value associated with that level.</p>
                <p>Ex: for talent, enter the level (10) instead of the talent points it gives (60). For bonus, enter the value directly (80%).</p>
            </Popover.Body>
        </Popover>
    );

    return (
        <Modal size="lg" backdrop="static" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title className="me-3">New knight</Modal.Title>
                <OverlayTrigger trigger="click" overlay={popover} placement="bottom" rootClose>
                    <Button variant="outline-secondary" >What&apos;s this?</Button>
                </OverlayTrigger>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <KnightModal knights={knights} currentKnights={currentKnights} formValue={formValue} setFormValue={setFormValue} />
                    <Modal.Footer>
                        <Button variant="primary" type="submit">
                            Add
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

AddKnightModal.propTypes = {
    show: PropTypes.bool,
    handleClose: PropTypes.func,
    knights: PropTypes.array,
    currentKnights: PropTypes.arrayOf(PropTypes.string)
}

export default AddKnightModal;